import {
    AfterViewInit,
    Component,
    ElementRef,
    Injector,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { TYPE_VALIDATE } from '@shared/AppEnums';
import { AppComponentBase } from '@shared/common/app-component-base';
import { HostSettingsServiceProxy } from '@shared/service-proxies/service-proxies';
import moment from 'moment';
// import * as moment from 'moment';



export interface commonResultValidDto {
    isError: boolean;
    messages: string;
}

@Component({
    selector: '<validation-custom>',
    template: `
    <span #mySpan class="form-control-feedback"
    class="{{isHidden ? '' : 'custom-error-validate'}} {{isInvalidData ? 'invalid-data-form' : ''}}"
    style="display: none;"
    [hidden]="isHidden">
        {{messages}}
    </span>`
})
export class ValidationCustomComponent extends AppComponentBase implements OnChanges, AfterViewInit {

    @ViewChild('mySpan') _mySpan: ElementRef;

    @Input() sModel: any;
    @Input() equalsModel: any;
    @Input() sMaxLength: number;
    @Input() sMinLength: number;
    @Input() sEqualLength: number;
    @Input() arrDataDuplicates: any;
    @Input() sValidRef: commonResultValidDto;
    @Input() sType: TYPE_VALIDATE;
    @Input() messagesInput: string;
    @Input() isNotValidateNullOrEmpty: boolean;
    @Input() nativeElement: any;
    @Input() bindingModel: any;
    @Input() maxValue: any;
    @Input() minValue: any;
    @Input() isFullHour: boolean;
    messages: string = '';
    isInvalidData: boolean = false;
    isHidden: boolean = false;
    isAfterViewInit: boolean = false;
    constructor(
        injector: Injector,

    ) {
        super(injector);

    }
    checkHidden(): void {
        this.messages = AppUtilityService.isNullOrWhiteSpace(this.messagesInput) ?
            this.l('KhongDuocDeTrongTruongNay') : this.messagesInput;

        this.isHidden = true;
        this.isInvalidData = false;
        //if (AppUtilityService.isNullOrEmpty(this.sModel) && this.sModel !=0) {
        if (AppUtilityService.isNullOrWhiteSpace(this.sModel)) {
            this.isHidden = this.isNotValidateNullOrEmpty;
        } else if (!AppUtilityService.isNullOrWhiteSpace(this.sMaxLength) && this.sModel.length > this.sMaxLength) {
            this.messages = AppUtilityService.isNullOrWhiteSpace(this.messagesInput) ? (this.FormatString(this.l("DuLieuKhongTheVuotQua{0}KiTu"), this.sMaxLength)) : this.messagesInput;
            this.isHidden = false;
        } else if (!AppUtilityService.isNullOrWhiteSpace(this.sMinLength) && this.sModel.length < this.sMinLength) {
            this.messages = AppUtilityService.isNullOrWhiteSpace(this.messagesInput) ? (this.FormatString(this.l("DuLieuNhapToiThieu{0}KiTu"), this.sMinLength)) : this.messagesInput;
            this.isHidden = false;
        } else if (!AppUtilityService.isNullOrWhiteSpace(this.sEqualLength) && this.sModel.length != this.sEqualLength) {
            this.messages = AppUtilityService.isNullOrWhiteSpace(this.messagesInput) ? (this.FormatString(this.l("DuLieuNhapPhaiBang{0}KiTu"), this.sEqualLength)) : this.messagesInput;
            this.isHidden = false;
        } else if (this.sType == TYPE_VALIDATE.Number && !AppUtilityService.validateNumber(this.sModel)) {
            this.messages = this.l('KiTuKhongDungDinhDang');
            this.isHidden = false;
        } else if (this.sType == TYPE_VALIDATE.Email && !AppUtilityService.validateEmail(this.sModel)) {
            this.messages = this.l('EmailKhongDungDinhDang');
            this.isHidden = false;
        } else if (this.sType == TYPE_VALIDATE.Moment && !AppUtilityService.validateMoment(this.sModel)) {
            this.messages = this.l('NhapNgayThangNam');
            this.isHidden = false;
        }
        else if (this.sType == TYPE_VALIDATE.OnlyNumberAndChar && !AppUtilityService.validateOnlyCharacterAndNumber(this.sModel)) {
            this.messages = this.l('ChiChoPhepChuCaiVaChuSoVaDauGachNgang');
            this.isHidden = false;
        }
        // else if (this.sType == TYPE_VALIDATE.Datetime && !AppUtilityService.validateDatetime(this.sModel)) {
        //   this.messages = "Nhập ngày/tháng/năm";
        //   this.isHidden = false;
        // }
        else if (this.sType == TYPE_VALIDATE.Datetime && this.maxValue && AppUtilityService.compareDatetime(this.sModel, this.maxValue, this.isFullHour) > 0) {
            let maxDate = new Date(this.maxValue);
            this.messages = this.FormatString(this.l('NgayNhapPhaiNhoHonHoacBang{0}'), (this.isFullHour ? moment(maxDate).format('DD/MM/YYYY HH:mm') : (maxDate.getDate() + "/" + (maxDate.getMonth() + 1) + "/" + maxDate.getFullYear())));
            this.isHidden = false;
        }
        else if (this.sType == TYPE_VALIDATE.Datetime && this.minValue && AppUtilityService.compareDatetime(this.sModel, this.minValue, this.isFullHour) < 0) {
            let minDate = new Date(this.minValue);
            this.messages = this.FormatString(this.l('NgayNhapPhaiLonHonHoacBang{0}'), (this.isFullHour ? moment(minDate).format('DD/MM/YYYY HH:mm') : (minDate.getDate() + "/" + (minDate.getMonth() + 1) + "/" + minDate.getFullYear())));
            this.isHidden = false;
        }
        else if (this.sType == TYPE_VALIDATE.MinValue && this.sModel != null && this.equalsModel != null && parseInt(this.sModel) < parseInt(this.equalsModel)) {
            this.messages = this.FormatString(this.l("GiaTriNhapKhongTheNhoHon{0}"), this.equalsModel);
            this.isHidden = false;
        } else if (this.sType == TYPE_VALIDATE.MaxValue && this.sModel != null && this.equalsModel != null && parseInt(this.sModel) > parseInt(this.equalsModel)) {
            this.messages = this.FormatString(this.l("GiaTriNhapKhongTheLonHon{0}"), this.equalsModel);
            this.isHidden = false;
        } else if (this.sType == TYPE_VALIDATE.PassWord && this.sModel != null && AppUtilityService.validatePassword(this.sModel) === false) {
            this.messages = this.l('MatKhauToiThieu8KyTuBaoGomChuVaSo');
            this.isHidden = false;
        } else if (this.sType == TYPE_VALIDATE.Phone && this.sModel != null && AppUtilityService.validateNumberPhone(this.sModel) === false) {
            this.messages = this.l('SoDienThoaiKhongDungDinhDang');
            this.isHidden = false;
        } else if (this.sType == TYPE_VALIDATE.CheckDuplicates && this.arrDataDuplicates != null && this.arrDataDuplicates.includes(this.sModel)) {
            this.messages = this.messagesInput || this.l('GiaTriNhapKhongHopLe');
            this.isInvalidData = true;
            this.isHidden = false;
        } else if (this.sType == TYPE_VALIDATE.Dynamic) {
            this.messages = this.messagesInput || this.l('GiaTriNhapKhongHopLe');
            this.isInvalidData = true;
            this.isHidden = false;
        }
        else if (this.sType == TYPE_VALIDATE.ValidRef && !AppUtilityService.isNullOrWhiteSpace(this.sModel) && this.sValidRef != null && this.sValidRef.isError) {
            this.messages = this.sValidRef.messages || this.l('GiaTriNhapKhongHopLe');
            this.isHidden = false;
        }
        else if (this.sType == TYPE_VALIDATE.Array && !AppUtilityService.isNullOrWhiteSpace(this.sModel) && this.sModel.length == 0) {
            this.messages = this.messagesInput || this.l('KhongDuocDeTrongTruongNay');
            this.isHidden = false;
        }
        else if (this.sType == TYPE_VALIDATE.Equal && !AppUtilityService.isNullOrWhiteSpace(this.equalsModel) && this.sModel != this.equalsModel) {
            this.messages = this.messagesInput || this.l('KiTuNhapKhongGiongVoiKiTuSoSanh');
            this.isHidden = false;
        }
        else if (this.sType == TYPE_VALIDATE.Ipv4 && this.sModel != null && AppUtilityService.validateAddressIpv4(this.sModel) === false) {
            this.messages = this.l('GiaTriNhapKhongHopLe');
            this.isHidden = false;
        }
        else if (this.sType == TYPE_VALIDATE.Ipv6 && this.sModel != null && AppUtilityService.validateAddressIpv6(this.sModel) === false) {
            this.messages = this.l('GiaTriNhapKhongHopLe');
            this.isHidden = false;
        }

        if (this.isAfterViewInit && this.nativeElement && this.nativeElement.style) {
            this.nativeElement.style.cssText = this.isHidden ?
                null :
                "color: #fd397a; border: 1px solid #fd397a;";
        }
    }

    ngOnChanges(_changes: SimpleChanges): void {
        if (this.isAfterViewInit) {
            if (_changes.sModel != null && _changes.sModel.currentValue) {
                this._mySpan.nativeElement.style.display = 'inline';
            }

        }

        this.checkHidden();

    }

    ngAfterViewInit() {
        this.isAfterViewInit = true;
        if (this.bindingModel) {
            this._mySpan.nativeElement.style.display = 'inline';
            this.checkHidden();
        }
    }

}
